import { render, staticRenderFns } from "./TFootInfo.vue?vue&type=template&id=44a03e6a&scoped=true"
import script from "./TFootInfo.vue?vue&type=script&lang=js"
export * from "./TFootInfo.vue?vue&type=script&lang=js"
import style0 from "./TFootInfo.vue?vue&type=style&index=0&id=44a03e6a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44a03e6a",
  null
  
)

export default component.exports